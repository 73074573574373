<template>
    <div class="home">
      <div style="display:flex;">
        <el-menu default-active="/comment/commentexaminelist" class="el-menu-demo" mode="horizontal" @select="handleSelect">
          <el-menu-item index="/comment/commentedit">审核详情</el-menu-item>
          <el-menu-item index="/comment/commentexaminelist">审核记录</el-menu-item>
        </el-menu> 
        <div style="
            height: 60px;
            line-height: 60px;
            right: 42px;
    position: fixed;"><i @click="handleSelect('/comment/commentlist')" class="el-icon-close"></i></div>
      </div>

      <el-table
        :data="tableData"
        style="width: 100%"
         >
        
        <el-table-column
          prop="updated_at"
          label="审核时间">
        </el-table-column>
        
        <el-table-column
          prop="status"
          label="审核结果"
          width="100">
          <template slot-scope="scope">
        {{scope.row.status == 1?'待审核':''}}{{scope.row.status == 2?'审核通过':''}}{{scope.row.status == 3?'审核未通过':''}}
          </template>
        </el-table-column>
        <el-table-column
          prop="violation"
          label="违规程度"
          width="100">
          <template slot-scope="scope">
        {{scope.row.violation == 1?'轻度':''}}{{scope.row.violation == 2?'重度':''}}{{scope.row.violation == 3?'删除':''}}
          </template>
        </el-table-column>
        <el-table-column
          prop="reason"
          label="未通过原因">
          <template slot-scope="scope">
        {{scope.row.reason[0]?scope.row.reason[0].name:''}}
        {{scope.row.reason[1]?scope.row.reason[1].name:''}}
        {{scope.row.reason[2]?scope.row.reason[2].name:''}}
        {{scope.row.reason[3]?scope.row.reason[3].name:''}}
        </template>
        </el-table-column>
        
        <el-table-column
          prop="remark" 
          label="备注">
        </el-table-column>
        <el-table-column
          prop="operator_name" 
          label="审核人员">
        </el-table-column>
      </el-table>
      <div class="pt20"></div>
        
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import config from '../../lib/config'
import formatDate from '../../lib/dateFormat'

export default {
  name: 'Home',
  components: {
    
  },
  data(){
    return {
      tableData: [],
      timevalue:'',
      timevalue2:'',
      // 选中数组
      ghs: [],
     //选中的记录数量
     selectedNum:0,
    }
  },
  methods: {
      handleSelect(url){
        this.$router.push(url)
      },
      onSubmit(){
        let _this = this;
          if(_this.timevalue){
            _this.formInline.e_sdate = formatDate(_this.timevalue[0]);
            _this.formInline.e_edate = formatDate(_this.timevalue[1]);
          }
          if(_this.timevalue2){
            _this.formInline.t_sdate = formatDate(_this.timevalue2[0]);
            _this.formInline.t_edate = formatDate(_this.timevalue2[1]);
          }
        axios.get(config.commentExamine,{params:this.formInline})
        .then(function (response) {
            if(response.data.code == 200){
              _this.tableData = response.data.data.data
            }
          })
        .catch(function (error) {
          console.log(error);
        });
      },
      goedit(obj){
        sessionStorage.setItem("edit", JSON.stringify(obj));
        this.$router.push('/comment/commentedit');
      },
      menuChangeStatus(status){
          let _this = this;
        if(this.selectedNum == 0){
          this.$message.error('请先勾选');
          return;
        }
        if(this.selectedNum != 1){
          this.$message.error('不支持批量');
          return;
        }
        this.$confirm('确认执行操作？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          axios.post(config.commentlistChangeStatus, {org_id:this.ghs[0],status:status})
        .then(response => {
          if(response.data.code == 200){
            _this.$message({
              message: response.data.message,
              type: 'success'
            });
            _this.init();
          }
        })
        .catch(error => {
          _this.$message.error(error);
        })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          });          
        });

      },
    handleSizeChange(){},
    handleCurrentChange(){},
    // 多选框选中数据
    handleSelectionChange(selection) {
        //获取所有选中项的gh(工号)属性的值
        this.ghs = selection.map(item => item.org_id)
        //获取所有选中项数组的长度
        this.selectedNum = selection.length
        console.log(this.ghs);
        console.log(this.selectedNum);
      },
    init(a){
        let obj = JSON.parse(a);
        let _this = this;
        console.log(obj);
        axios.get(config.commentLog,{params:{no:obj.no}})
        .then(function (response) {
            if(response.data.code == 200){
              _this.tableData = response.data.data.data
            }
          })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  created(){
     let a = sessionStorage.getItem('edit');
      this.init(a);
  }
}
</script>
<style scoped>
.left{
    width: 335px;
}
.el-menu--horizontal > .el-menu-item.is-active{
    font-weight: bold;
}
.el-icon-close:before{
  font-size: 24px;
}
.el-menu-item{
  font-size: 16px;
}
</style>